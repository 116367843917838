import * as C from "../../components/styled/index";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../App";
import FullPageSpinner from "../../components/Spinner";
import PreCheckModal from "./component/precheckModal";
import moment from "moment";
import RentalListItem from "./component/rentalItem";
import { TableWrapBox } from "./commons/styled";
import useUserAuth from "../../components/hooks/useUserAuth";
import { useQuery } from "@tanstack/react-query";
import UploadCSVModal from "./component/UploadModal";
import { useAccountList, useManangementList } from "./commons/queries";
import { manage_fee_key } from "../../lib/api/queries/keys";
import { fetchClient } from "../../lib/api/axios";
import { manage_fee } from "../../lib/api/queries/urls";
import { IRentalList } from "../../lib/types/Imodels";
import { BaseResponse } from "../../lib/api/queries/commons/types";
import NotFound from "../not-found";
import { warning } from "../../lib/theme/toast";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import { styled_btn } from "../../lib/theme/common";

export default function MaintenanceFeeListPage() {
  const toast = useToast();
  const navigate = useNavigate();
  const { buildInfo } = useContext(GlobalContext);
  const hasFunc = buildInfo?.services.MAINTENANCE_FEE?.enabled;
  const [year, setYear] = useState(new Date());
  const auth = useUserAuth("management_fee");
  const { data: bank } = useAccountList();
  const { data: tenants } = useManangementList(true);
  const { isOpen, onOpen, onClose } = useDisclosure(); //업로드 전 계좌 등록 모달
  const { isOpen: isFile, onOpen: onFile, onClose: offFile } = useDisclosure(); //파일 업로드

  const { data } = useQuery(
    [manage_fee_key, year],
    () =>
      fetchClient
        .get<BaseResponse<IRentalList[]>>(manage_fee, {
          params: {
            date: `${year.getFullYear()}-01-01`,
          },
        })
        .then((res) => res.data.data),
    { enabled: auth === "edit" || auth === "read" },
  );

  const onTransferRoute = () => {
    if (!tenants || tenants.data1.length === 0)
      return toast({
        ...warning,
        title: "알림",
        description:
          "관리비 등록을 위해서 호실을 먼저 등록해주세요. 호실 등록 후에도 동일한 알림이 뜰 경우 오피스너 담당자에게 문의해주세요.",
      });

    const isExist = !bank?.length || !buildInfo?.payment_date;
    if (isExist) return onOpen();
    buildInfo.management_fee_upload_type === "file" //파일 직접 올리기
      ? onFile()
      : navigate("/maintenance-fee/create");
  };

  if (hasFunc === undefined || !auth) return <FullPageSpinner />;
  if (!hasFunc) return <NotFound />;

  return (
    <div className="flex min-h-full w-full flex-col bg-white p-0 md:px-6">
      <div className="flex w-full justify-between py-5">
        <C.DateWithIconBox>
          <DatePicker
            locale={ko}
            selected={year}
            dateFormat="MM/yyyy"
            showYearPicker
            onChange={(date: Date) => setYear(date)}
            customInput={
              <C.InnerTextBox>
                <Text>{moment.utc(year).format("YYYY년")}</Text>
                <FiChevronDown />
              </C.InnerTextBox>
            }
          />
        </C.DateWithIconBox>
        {auth === "edit" && (
          <>
            <Button variant="bgBlue" style={{ ...styled_btn }} onClick={onTransferRoute}>
              관리비 고지서 생성
            </Button>
            <UploadCSVModal isOpen={isFile} onClose={offFile} isEdit={false} />
            <PreCheckModal isOpen={isOpen} onClose={onClose} onClick={() => navigate("/maintenance-fee/bank")} />
          </>
        )}
      </div>
      <C.ListTableBox>
        <C.ListTableHeader w={buildInfo?.management_fee_upload_type === "direct" ? "20%" : "25%"}>
          <TableWrapBox style={{ width: "80%" }}>
            <div>부과년월</div>
            {buildInfo?.management_fee_upload_type === "direct" && <div>총 부과금액</div>}
            <div>최종편집자</div>
            <div>업데이트일시</div>
          </TableWrapBox>
          <div>고지 상태</div>
        </C.ListTableHeader>
        {data?.map((el) => (
          <RentalListItem
            key={String(el._id)}
            el={el}
            auth={auth}
            type={buildInfo?.management_fee_upload_type === "direct"}
            path={`/maintenance-fee/${el._id}`}
          />
        ))}
      </C.ListTableBox>
    </div>
  );
}
