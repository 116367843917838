import Axios, { InternalAxiosRequestConfig } from "axios";
import { auth } from "./firebase";
import { BaseResponse } from "./api/queries/commons/types";
import { webViewToken } from "./http";

const interceptors = async (config: InternalAxiosRequestConfig<unknown>) => {
  if (webViewToken) {
    config.headers.token = webViewToken;
    return config;
  } else {
    const token = await auth.currentUser?.getIdToken();
    if (!token) throw new Error();
    config.headers.token = token;
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.usertype = "manager";
  }
  return config;
};

export const axiosInstance = Axios.create();
axiosInstance.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
axiosInstance.defaults.withCredentials = false;
axiosInstance.interceptors.request.use(interceptors);

export const httpV1 = {
  get: async function get<Response = unknown, Params = unknown>(url: string, params?: Params) {
    return axiosInstance.get<BaseResponse<Response>>(url, { params }).then((res) => res.data);
  },
  post: async function post<Request = unknown, Response = unknown>(url: string, data?: Request) {
    return axiosInstance.post<BaseResponse<Response>>(url, data).then((res) => res.data);
  },
  del: async function del<Response = unknown>(url: string) {
    return axiosInstance.delete<BaseResponse<Response>>(url).then((res) => res.data);
  },
  put: async function put<Request = unknown, Response = unknown>(url: string, data?: Request) {
    return axiosInstance.put<BaseResponse<Response>>(url, data).then((res) => res.data);
  },
  patch: async function patch<Request = unknown, Response = unknown>(url: string, data?: Request) {
    return axiosInstance.patch<BaseResponse<Response>>(url, data).then((res) => res.data);
  },
};
