import { http } from "../../lib/http";
import { useQuery } from "@tanstack/react-query";

type Request = {
  rent_fee_id: string;
};

type Response = {
  data: {
    date: Date;
    updated_date: Date;
    user_name: string;
    rent_fee_details: {
      _id: string;
      tenant_id: string;
      tenant_name: string;
      dong_ho: {
        _id: string;
        dong: string;
        ho: string;
      }[];
      price?: number;
      payment_status?: string;
    }[];
  };
};

export const useGetRentFeeDetail = ({ rentFeeId }: { rentFeeId: string }, enabled: boolean) => {
  return useQuery({
    queryKey: ["rent_fee", rentFeeId],
    queryFn: () =>
      http.post<Request, Response>("/building/rent_fee", {
        rent_fee_id: rentFeeId,
      }),
    enabled,
  });
};
