import { useMutation } from "@tanstack/react-query";
import { httpV1 } from "../../lib/httpV1";
import { queryClient } from "../..";

type Request = {
  notificationId: string;
};

export const useUpdateNotification = () => {
  return useMutation({
    mutationFn: ({ notificationId }: Request) => {
      return httpV1.post("/building/notification", { notification_id: notificationId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["/building/notification/list"]);
    },
  });
};
