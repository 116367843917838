import { useDisclosure, useToast } from "@chakra-ui/react";
import FullPageSpinner from "../../components/Spinner";
import NotFound from "../not-found";
import * as C from "../../components/styled/index";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import { GlobalContext } from "../../App";
import PreCheckModal from "../maintenance-fee/component/precheckModal";
import useUserAuth from "../../components/hooks/useUserAuth";
import GetAuthorized from "../../components/Authrized";
import { useAccountList } from "../maintenance-fee/commons/queries";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import { useGetRentFeeList } from "../../requests/rent-fee/useGetRentFeeList";
import { twMerge } from "tailwind-merge";
import { useUpdateRentFeeNotify } from "../../requests/rent-fee/useUpdateRentFeeNotify";
import { unAuthUser } from "../../lib/theme/toast";

export default function RentFeeListPage() {
  const navigate = useNavigate();
  const { buildInfo } = useContext(GlobalContext);
  const auth = useUserAuth("rent_fee");
  const hasFunc = buildInfo?.services.RENT_FEE?.enabled;
  const [year, setYear] = useState(new Date());
  const { data: bank } = useAccountList();
  const { isOpen, onOpen, onClose } = useDisclosure(); //업로드 전 계좌 등록 모달
  const isExist = !bank?.length || !buildInfo?.payment_date;

  const toast = useToast();

  const { data } = useGetRentFeeList({
    date: moment(year).format("YYYY-01-01"),
    enabled: auth === "edit" || auth === "read",
  });
  const { mutateAsync: updateRentFeeNotify, isLoading: isUpdateLoading } = useUpdateRentFeeNotify();

  if (hasFunc === undefined || !auth) return <FullPageSpinner />;
  if (!hasFunc) return <NotFound />;
  if (auth === "no_auth") return <GetAuthorized />;
  return (
    <div className="flex min-h-full w-full flex-col bg-white p-0 md:px-6">
      <div className="flex w-full justify-between py-5">
        <C.DateWithIconBox>
          <DatePicker
            locale={ko}
            selected={year}
            dateFormat="MM/yyyy"
            showYearPicker
            onChange={(date: Date) => setYear(date)}
            customInput={
              <div className="relative flex w-fit cursor-pointer items-center gap-1 border border-gray-100 bg-gray-100 px-3 py-2 text-[15px] font-medium text-gray-500">
                <p>{moment.utc(year).format("YYYY년")}</p>
                <FiChevronDown className="h-5 w-5" />
              </div>
            }
          />
        </C.DateWithIconBox>
        {auth === "edit" && (
          <>
            <button
              onClick={() => (isExist ? onOpen() : navigate("/rent-fee/create"))}
              className="border border-blue-600 bg-blue-600 px-3 py-2 text-[15px] text-white"
            >
              임대료 고지서 생성
            </button>
            <PreCheckModal isOpen={isOpen} onClose={onClose} onClick={() => navigate("/account/list")} />
          </>
        )}
      </div>
      <div className="w-full border-t border-gray-500">
        <div className="flex h-12 w-full items-center justify-around border-b border-gray-500 bg-gray-100 py-2.5 text-center">
          <div className="w-1/5">부과년월</div>
          <div className="w-1/5">총 부과금액</div>
          <div className="w-1/5">최종편집자</div>
          <div className="w-1/5">업데이트일시</div>
          <div className="w-1/5">고지 상태</div>
        </div>
        {data?.data.map((el) => (
          <div
            key={el._id.toString()}
            className={twMerge(
              "flex min-h-12 w-full cursor-pointer items-center border-b border-gray-400 py-2.5 text-center transition-all duration-75 hover:bg-blue-50 hover:opacity-75",
              el?.is_open && "py-2.5",
            )}
            onClick={() => navigate(`/rent-fee/${el._id}`)}
          >
            <div className="flex w-4/5 justify-around">
              <div className={"w-1/5"}>{moment(el?.date).format("YYYY년 MM월")}</div>

              <div className={"w-1/5"}>{(el?.sum_of_rent_fee || 0).toLocaleString("kr")}원</div>
              <div className={"w-1/5"}>{el?.user_name}</div>
              <div className={"w-1/5"}>
                {el?.updated_date ? moment.utc(el.updated_date).format("YYYY-MM-DD HH:mm") : ""}
              </div>
            </div>
            <div className={"w-1/5"}>
              {el?.is_open ? (
                <div>고지완료</div>
              ) : (
                <>
                  {auth !== "edit" ? (
                    <div>등록완료</div>
                  ) : (
                    <button
                      className="rounded-md bg-blue-600 p-2 text-[15px] text-white"
                      onClick={async (e) => {
                        e.stopPropagation();

                        if (auth !== "edit") return toast({ ...unAuthUser });
                        if (isUpdateLoading) return;

                        const isUpdateRentFeeNotifySuccess = await updateRentFeeNotify({
                          rentFeeId: el?._id.toString(),
                        });

                        isUpdateRentFeeNotifySuccess && alert("입주자에게 임대료 납부 고지서를 전송했습니다.");
                      }}
                    >
                      보내기
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
