import { useQuery } from "@tanstack/react-query";
import { httpV1 } from "../../lib/httpV1";

type Request = {
  buildingId?: string;
  enabled: boolean;
};

type Response = {
  _id: string;
  building_id: string;
  user_id: string;
  notification_type: string;
  title: string;
  body: string;
  data: string;
  is_checked: boolean;
  createdAt: Date;
  tenant_id: string;
  tenant_name: string;
}[];

export const useGetNotifications = ({ buildingId, enabled }: Request) => {
  return useQuery({
    queryKey: ["/building/notification/list", buildingId],
    queryFn: () => httpV1.get<Response>("/building/notification/list"),
    enabled,
  });
};
