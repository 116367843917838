import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import moment from "moment";
import { MouseEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { queryClient } from "../../..";
import { fetchClient } from "../../../lib/api/axios";
import { manage_fee_key, rental_list_key } from "../../../lib/api/queries/keys";
import { notify_manage, notify_rental } from "../../../lib/api/queries/urls";
import { unAuthUser } from "../../../lib/theme/toast";
import { ChangeButton } from "../commons/styled";
import { IBillLetterList } from "../commons/types";
import { twMerge } from "tailwind-merge";

export default function RentalListItem({ path, el, auth, type }: IBillLetterList) {
  const toast = useToast();
  const params = useLocation();
  const navigate = useNavigate();

  const mutation = useMutation((newObj: { rent_fee_id?: string; management_fee_id?: string }) =>
    fetchClient
      .post(params.pathname.includes("rent-fee") ? notify_rental : notify_manage, newObj, {
        headers: {
          ContentType: "application/json",
        },
      })
      .then(() => {
        params.pathname.includes("rent-fee")
          ? queryClient.invalidateQueries([rental_list_key])
          : queryClient.invalidateQueries([manage_fee_key]);
        alert(`입주자에게 ${params.pathname.includes("rent-fee") ? "임대료" : "관리비"} 납부 고지서를 전송했습니다.`);
      })
      .catch((err: AxiosError) => err.response?.status === 401 && toast({ ...unAuthUser })),
  );

  const onNotifyUser = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (auth !== "edit") return toast({ ...unAuthUser });
    if (mutation.isLoading) return;
    const newObj: {
      rent_fee_id?: string;
      management_fee_id?: string;
    } = {};
    if (params.pathname.includes("rent-fee")) {
      newObj.rent_fee_id = String(el?._id || "");
    } else {
      newObj.management_fee_id = String(el?._id || "");
    }
    mutation.mutate(newObj);
  };

  return (
    <>
      <div
        className={twMerge(
          "flex w-full cursor-pointer border-b border-gray-400 py-1 transition-all duration-75 hover:bg-blue-50 hover:opacity-75",
          el?.is_open && "py-2.5",
        )}
        onClick={() => navigate(path)}
      >
        <div className="flex w-4/5 justify-around">
          <div className={twMerge("w-1/4", type && "w-1/5")}>{moment(el?.date).format("YYYY년 MM월")}</div>
          {type && (
            <div className={"w-1/5"}>
              {Number(
                path.includes("rent-fee") ? el?.sum_of_rent_fee || 0 : el?.sum_of_management_fee || 0,
              ).toLocaleString("kr")}
              원
            </div>
          )}
          <div className={twMerge("w-1/4", type && "w-1/5")}>{el?.user_name}</div>
          <div className={twMerge("w-1/4", type && "w-1/5")}>
            {el?.updated_date ? moment.utc(el.updated_date).format("YYYY-MM-DD HH:mm") : ""}
          </div>
        </div>
        <div className={twMerge("w-1/4", type && "w-1/5")}>
          {el?.is_open ? (
            <div>고지완료</div>
          ) : (
            <>
              {auth !== "edit" ? (
                <div>등록완료</div>
              ) : (
                <ChangeButton onClick={(e) => onNotifyUser(e)}>보내기</ChangeButton>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
