import styled from "@emotion/styled";
import { ReactComponent as Lamp } from "../assets/lamp.svg";
import { ReactComponent as Profile } from "../assets/profile.svg";
import { ReactComponent as Home } from "../assets/home.svg";
import { colors } from "../../styled/common";
import { useLocation, useNavigate } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import NotificationBox from "../header/notification";
import { NewAlertIcon } from "../header/styled";
import { breakPoints } from "../../styled/media";
import { INotifications } from "..";
import { queryClient } from "../../..";
import UserPageForApp from "./userPage";
import { useState } from "react";

const WrapperFooter = styled.div`
  width: 100%;
  height: 55px;
  padding: 8px;
  border-top: 1px solid ${colors.gray1};
`;

const FooterAlign = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

const ClickBox = styled.div<{ isActive: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  color: ${({ isActive }) => (isActive ? colors.mainBlue : colors.gray4)};
  svg {
    width: 24px;
    height: 24px;
    margin-bottom: 4px;
  }
`;

const Dots = styled(NewAlertIcon)`
  top: 4px;
  right: 1px;
  width: 0.5rem;
  height: 0.5rem;
  @media ${breakPoints.mobile} {
    top: 4px;
    right: 1.5px;
  }
`;

export default function LayoutFooter(props: INotifications) {
  const { dots, setDots, list: data } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure(); // 알림
  const [isUser, setIsUser] = useState(false);
  const isActive = location.pathname.includes("/complaints") && !isUser && !isOpen;
  // const { isOpen: isUser, onOpen: onUser, onClose: offUser } = useDisclosure(); // 유저페이지

  const onClickToMovePage = () => {
    onClose();
    setIsUser(false);
    if (location.pathname === "/complaints") return;
    navigate("/complaints");
  };

  const onOpenDrawer = (type: string) => {
    if (type === "notification") {
      console.log("passed");
      queryClient.invalidateQueries(["/building/notification/list"]);
      onOpen();
    } else {
      setIsUser((prev) => !prev);
    }
  };

  return (
    <>
      <WrapperFooter>
        <FooterAlign>
          <ClickBox isActive={isActive} onClick={onClickToMovePage}>
            <Home fill={isActive ? colors.mainBlue : "#D9D9D9"} />
            <p>민원</p> {/* 여기는 모바일 앱 추가 개발되면서 바뀔 것  */}
          </ClickBox>
          <ClickBox style={{ position: "relative" }} isActive={isOpen} onClick={() => onOpenDrawer("notification")}>
            <Lamp fill={isOpen ? colors.mainBlue : "#D9D9D9"} />
            <Dots dots={dots} />
            <p>알림</p>
          </ClickBox>
          {data && isOpen && <NotificationBox data={data} isOpen={isOpen} onClose={onClose} setDots={setDots} />}
          <ClickBox isActive={isUser}>
            <Profile onClick={() => onOpenDrawer("user")} fill={isUser ? colors.mainBlue : "#D9D9D9"} />
            <p>마이페이지</p>
          </ClickBox>
        </FooterAlign>
      </WrapperFooter>
      <UserPageForApp isOpen={isUser} onClose={() => setIsUser(false)} />
    </>
  );
}
