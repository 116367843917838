import { Route, Routes } from "react-router-dom";
import "./App.css";
import { createContext, Dispatch, SetStateAction, useEffect, useState } from "react";
import "./lib/firebase/index";
import { auth, updateFCMToken } from "./lib/firebase";
import { IBuilding, IUser } from "./lib/types/Imodels";
import ProtectRoute from "./lib/route/privateRoute";
import PublicRoute from "./lib/route/publicRoute";
import MainPage from "./pages/Login";
import NoticePage from "./pages/notice/page";
import DetailNotification from "./pages/notice/[noticeId]/page";
import EditNotification from "./pages/notice/[noticeId]/edit/page";
import NewNotification from "./pages/notice/new/page";
import OfficeSettings from "./pages/settings/info/page";
import ManagerSettings from "./pages/settings/manager/page";
import NoticeClassification from "./pages/notice/classification/page";
import PublicReservation from "./pages/meeting-rooms/page";
import NotFound from "./pages/not-found";
import PublicFacilityList from "./pages/meeting-rooms/setting/page";
import NewSurveyPage from "./pages/survey/new/page";
import SurveyResultPage from "./pages/survey/[surveyId]/page";
import SurveyEditPage from "./pages/survey/[surveyId]/edit/page";
import DashBoardPage from "./pages/dashBoard/page";
import GetMoveInForms from "./pages/forms";
import RegisterFormResult from "./pages/forms/submit";
import BuildingBeforeRegisterCard from "./pages/forms/building";
import CreateMealPlan from "./pages/cafeteria/new/page";
import DetailMealPlan from "./pages/cafeteria/[plannerId]/page";
import EditMealPlanner from "./pages/cafeteria/[plannerId]/edit/page";
import MaintenanceFeeCreatePage from "./pages/maintenance-fee/create/page";
import PublicFacilityFrame from "./pages/facility/[publicType]/page";
import FixedFacilityRestrict from "./pages/facility/[publicType]/restrict/page";
import FixedFacilityOperation from "./pages/facility/[publicType]/operation/page";
import CreateNewFacility from "./pages/facility/[publicType]/create/page";
import CreateBookingItems from "./pages/facility/booking/[publicId]/create/page";
import BookingListFrame from "./pages/facility/booking/[publicId]/page";
import BookingItemList from "./pages/facility/booking/[publicId]/list/[itemsId]/page";
import BookingItemDetails from "./pages/facility/booking/[publicId]/list/[itemsId]/page";
import MobileRoute from "./lib/route/mobile";
import { instance, interceptors } from "./lib/api/axios";
import VotesPage from "./pages/votes/page";
import VoteDetailPage from "./pages/votes/[voteId]/page";
import MealPlanner from "./pages/cafeteria/page";
import CompanyCardPage from "./pages/companies/cards/page";
import CompanyListPage from "./pages/companies/page";
import CompanyLayout from "./pages/companies/layout";
import RoomListPage from "./pages/rooms/page";
import RoomDetailPage from "./pages/rooms/[id]/page";
import CompanyDetailInfoPage from "./pages/companies/[companyId]/info/page";
import CompanyDetailMemberPage from "./pages/companies/[companyId]/members/page";
import CompanyDetailLayout from "./pages/companies/[companyId]/layout";
import MemberLayout from "./pages/members/layout";
import MemberRequestPage from "./pages/members/requests/page";
import MemberListPage from "./pages/members/page";
import MemberDetailLayout from "./pages/members/[memberId]/layout";
import MemberDetailCompanyPage from "./pages/members/[memberId]/companies/page";
import MemberDetailRoomPage from "./pages/members/[memberId]/rooms/page";
import { ToastBar, Toaster } from "react-hot-toast";
import ParkingPage from "./pages/parking/page";
import SurveyPage from "./pages/survey/page";
import MeetingRoomLayout from "./pages/meeting-rooms/layout";
import RentFeeListPage from "./pages/rent-fee/page";
import RentFeeCreatePage from "./pages/rent-fee/create/page";
import MaintenanceFeeDetailPage from "./pages/maintenance-fee/[billId]/page";
import MaintenanceFeeEditPage from "./pages/maintenance-fee/[billId]/edit/page";
import RentFeeDetailPage from "./pages/rent-fee/[rentalId]/page";
import RentFeeEditPage from "./pages/rent-fee/[rentalId]/edit/page";
import AdministrationBank from "./pages/maintenance-fee/bank";
import SettingLayout from "./pages/settings/layout";
import MeetingRoomPage from "./pages/meeting-rooms/timeline/page";
import RoomCreate from "./pages/rooms/create";
import RoomModify from "./pages/rooms/modify/[id]";
import { OverlayProvider } from "@toss/use-overlay";
import TemperatureLayout from "./pages/temperature/layout";
import ControlTemperature from "./pages/temperature/page";
import TemperatureHistory from "./pages/temperature/history/page";
import MaintenanceFeeListPage from "./pages/maintenance-fee/page";
import PointLayout from "./pages/points/layout";
import PointHistoryPage from "./pages/points/page";
import PointSettingPage from "./pages/points/setting/page";
import PointHistoryDetailPage from "./pages/points/[tenantId]/page";
import PointSettingManage from "./pages/points/setting/manage/page";
import ResourceListPage from "./pages/resources/page";
import ResourceCreatePage from "./pages/resources/create/page";
import ResourceDetailPage from "./pages/resources/[faqId]/page";
import ResourceEditPage from "./pages/resources/[faqId]/edit/page";
import ComplaintListPage from "./pages/complaints/page";
import ComplaintTodoListPage from "./pages/complaints/todo/page";
import ComplaintCreatePage from "./pages/complaints/create/page";
import ComplaintDetailPage from "./pages/complaints/[complaintId]/page";
import ComplaintEditPage from "./pages/complaints/[complaintId]/edit/page";
import CreateCompanyPage from "./pages/companies/new/page";
import MaintenanceFeeLayout from "./pages/maintenance-fee/layout";
import ErrorBoundary from "./views/ErrorBoundary";
import { setWebViewToken } from "./lib/http";
import RentFeeLayout from "./pages/rent-fee/layout";
import RentFeeBankPage from "./pages/rent-fee/bank/page";
import MemberHistoryListPage from "./pages/members/history/page";

interface WebViewEvent {
  type?: string;
  data?: string;
}

interface PropsContextType {
  user?: IUser;
  setUser?: Dispatch<SetStateAction<IUser | undefined>>;
  buildInfo?: IBuilding | undefined;
  setBuildInfo?: Dispatch<SetStateAction<IBuilding | undefined>>;
  AppToken?: string | null;
}

export const GlobalContext = createContext<PropsContextType>({});

function App() {
  const [user, setUser] = useState<IUser>();
  const [buildInfo, setBuildInfo] = useState<IBuilding>();

  const [AppToken, setAppToken] = useState<string | null>(null);

  const getMessage = (event: MessageEvent) => {
    try {
      const from_app_event: WebViewEvent = JSON.parse(event.data);

      if (from_app_event.type === "message" && from_app_event.data) {
        instance.interceptors.request.eject(instance.interceptors.request.use(interceptors));
        instance.interceptors.request.use(async (config) => {
          if (!config?.headers || !from_app_event.data) return config;
          config.headers.token = from_app_event.data;
          return config;
        });
        setWebViewToken(from_app_event.data);
        return setAppToken(from_app_event.data);
      }
    } catch (err) {
      if (AppToken !== null) return;
      setWebViewToken(null);
      return setAppToken(null);
    }
  };

  const value = { user, setUser, buildInfo, setBuildInfo, AppToken };

  const checkNotificationPermission = async () => {
    const isSupport = () => "Notification" in window && "serviceWorker" in navigator && "PushManager" in window;

    if (!isSupport()) return;

    const permission = await Notification.requestPermission();

    if (permission !== "granted") {
      if (permission === "denied") {
        alert(
          "알림을 차단하셨습니다.\n 오피스너에서 보내는 알림을 받으시려면 브라우저의 사이트 설정에서 알림 설정을 변경해주세요.",
        );
      }
      return;
    }

    auth.onAuthStateChanged((user) => {
      if (user != null) updateFCMToken();
    });

    window.addEventListener("message", getMessage);
  };

  useEffect(() => {
    checkNotificationPermission();

    return () => window.removeEventListener("message", getMessage);
  }, []);

  return (
    <div className="h-full font-pre">
      <GlobalContext.Provider value={value}>
        <OverlayProvider>
          <Toaster position="top-right">
            {(t) => (
              <ToastBar
                toast={t}
                style={{
                  borderRadius: "0.375rem",
                  background: "#1f2937",
                  // div margin 존재 4 10
                  padding: "12px 6px",
                  maxWidth: "500px",
                }}
              >
                {({ message }) => <>{message}</>}
              </ToastBar>
            )}
          </Toaster>
          <ErrorBoundary>
            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route element={<PublicRoute />}>
                <Route path="/" element={<MainPage />} />
                <Route path="/forms" element={<BuildingBeforeRegisterCard />} />
                <Route path="/forms/:building_id" element={<GetMoveInForms />} />
                <Route path="/forms/submit" element={<RegisterFormResult />} />
              </Route>
              <Route element={<ProtectRoute />}>
                <Route element={<MobileRoute />}>
                  {/* 대시보드 */}
                  <Route path="dashboard" element={<DashBoardPage />} />
                  {/* 건물 기본 설정 */}
                  <Route path="settings" element={<SettingLayout />}>
                    {/* <Route path="" element={<SettingPage />} /> */}
                    <Route path="info" element={<OfficeSettings />} />
                    <Route path="manager" element={<ManagerSettings />} />
                  </Route>
                  {/* 호실 관리 */}
                  <Route path="rooms">
                    <Route index element={<RoomListPage />} />
                    <Route path=":id" element={<RoomDetailPage />} />
                    {/* 임시 이전 화면 롤백 */}
                    <Route path="create" element={<RoomCreate />} />
                    <Route path="modify/:room_id" element={<RoomModify />} />
                  </Route>
                  {/* 입주사 관리 */}
                  <Route path="companies">
                    <Route element={<CompanyLayout />}>
                      <Route index element={<CompanyListPage />} />
                      <Route path="cards" element={<CompanyCardPage />} />
                    </Route>
                    <Route path="new" element={<CreateCompanyPage />} />
                    <Route path=":companyId" element={<CompanyDetailLayout />}>
                      <Route path="info" element={<CompanyDetailInfoPage />} />
                      <Route path="members" element={<CompanyDetailMemberPage />} />
                    </Route>
                  </Route>
                  {/* 회의실 */}
                  <Route path="meeting-rooms" element={<MeetingRoomLayout />}>
                    <Route index element={<PublicReservation />} />
                    <Route path="timeline" element={<MeetingRoomPage />} />
                    <Route path="setting" element={<PublicFacilityList />} />
                    {/* public_id를 사용하지 않아 임시 주석 확인 후 삭제 예정 */}
                    {/* <Route path="reservation/:public_id" element={<PublicReservation />} /> */}
                  </Route>
                  {/* 회원관리 */}
                  <Route path="members">
                    <Route element={<MemberLayout />}>
                      <Route index element={<MemberListPage />} />
                      <Route path="requests" element={<MemberRequestPage />} />
                      <Route path="history" element={<MemberHistoryListPage />} />
                    </Route>
                    <Route path=":memberId" element={<MemberDetailLayout />}>
                      <Route path="companies" element={<MemberDetailCompanyPage />} />
                      <Route path="rooms" element={<MemberDetailRoomPage />} />
                    </Route>
                  </Route>
                  {/* 투표 */}
                  <Route path="/votes" element={<VotesPage />} />
                  <Route path="/votes/:voteId" element={<VoteDetailPage />} />
                  {/* 공지사항 */}
                  <Route path="notice">
                    <Route index element={<NoticePage />} />
                    <Route path="new" element={<NewNotification isEdit={false} />} />
                    <Route path=":noticeId" element={<DetailNotification />} />
                    <Route path=":noticeId/edit" element={<EditNotification />} />

                    {/* 따로 이동하는 버튼은 없지만 게시글 유형 추가삭제하는 페이지로 보류 */}
                    <Route path="classification" element={<NoticeClassification />} />
                  </Route>
                  {/* 구내식당 */}
                  {/* 구내식당에 맞는 url로 수정 */}
                  {/* <Route path="additional"> */}
                  <Route path="cafeteria">
                    <Route index element={<MealPlanner />} />
                    <Route path="new" element={<CreateMealPlan isEdit={false} />} />
                    <Route path=":plannerId" element={<DetailMealPlan />} />
                    <Route path=":plannerId/edit" element={<EditMealPlanner />} />
                    {/* <Route path="detail/:planner_id" element={<DetailMealPlan />} /> */}
                  </Route>
                  {/* 입주사 */}
                  {/* <Route path="company">
                <Route path="list" element={<CompanyPage />} />
                <Route path="registration" element={<RegistrationList />} />
                <Route path="room/create" element={<RoomCreate />} />
                <Route path="room/modify/:room_id" element={<RoomModify />} />
              </Route> */}
                  {/* 온도피드백 */}
                  <Route path="temperature" element={<TemperatureLayout />}>
                    <Route index element={<ControlTemperature />} />
                    <Route path="history" element={<TemperatureHistory />} />
                  </Route>
                  {/* 설문조사 */}
                  <Route path="survey">
                    <Route index element={<SurveyPage />} />
                    <Route path="new" element={<NewSurveyPage isEdit={false} />} />
                    <Route path=":surveyId" element={<SurveyResultPage />} />
                    <Route path=":surveyId/edit" element={<SurveyEditPage />} />
                  </Route>
                  {/* 사용하지 않아 주석 사용한다면 코드 분석 필요 */}
                  {/* 공용시설 */}
                  <Route path="facility/:publicType">
                    <Route index element={<PublicFacilityFrame />} />
                    <Route path="create" element={<CreateNewFacility />} />
                    <Route path="operation" element={<FixedFacilityOperation />} />
                    <Route path="restricted" element={<FixedFacilityRestrict />} />
                    <Route path="booking/:publicId">
                      <Route index element={<BookingListFrame />} />
                      <Route path="create" element={<CreateBookingItems />} />
                      <Route path="status" element={<FixedFacilityOperation />} />
                      <Route path="items" element={<BookingItemList />} />
                      <Route path="items/:itemsId" element={<BookingItemDetails />} />
                    </Route>
                  </Route>
                  {/* 관리비 */}
                  <Route path="maintenance-fee">
                    <Route element={<MaintenanceFeeLayout />}>
                      <Route index element={<MaintenanceFeeListPage />} />
                      <Route path="bank" element={<AdministrationBank />} />
                    </Route>
                    <Route path="create" element={<MaintenanceFeeCreatePage />} />
                    <Route path=":billId" element={<MaintenanceFeeDetailPage />} />
                    <Route path=":billId/edit" element={<MaintenanceFeeEditPage />} />
                  </Route>
                  {/* 임대료 */}
                  <Route path="rent-fee">
                    <Route element={<RentFeeLayout />}>
                      <Route index element={<RentFeeListPage />} />
                      <Route path="bank" element={<RentFeeBankPage />} />
                    </Route>
                    <Route path="create" element={<RentFeeCreatePage />} />
                    <Route path=":rentalId" element={<RentFeeDetailPage />} />
                    <Route path=":rentalId/edit" element={<RentFeeEditPage />} />
                  </Route>

                  {/* 계좌 설정 */}
                  {/* <Route path="account/list" element={<AdministrationBank />} /> */}

                  {/* 주차 */}
                  <Route path="parking" element={<ParkingPage />} />
                  {/* 포인트관리 */}
                  <Route path="points">
                    <Route element={<PointLayout />}>
                      <Route index element={<PointHistoryPage />} />
                      <Route path="setting" element={<PointSettingPage />} />
                    </Route>
                    <Route path=":tenantId" element={<PointHistoryDetailPage />} />
                    <Route path="setting/manage" element={<PointSettingManage />} />
                  </Route>
                  {/* 자료실 */}
                  <Route path="resources">
                    <Route index element={<ResourceListPage />} />
                    <Route path="create" element={<ResourceCreatePage />} />
                    <Route path=":faqId" element={<ResourceDetailPage />} />
                    <Route path=":faqId/edit" element={<ResourceEditPage />} />
                  </Route>
                  {/* 민원 */}
                  {/* <Route path="request">
                  <Route index element={<ComplaintHistory />} />
                  <Route path="todos/:department_id" element={<ComplaintHistory />} />
                  <Route path="new" element={<SubmitNewRequest isEdit={false} />} />
                  <Route path="detail/:complaint_id" element={<ComplaintsDetail />} />
                  <Route path="detail/:complaint_id/edit" element={<EditComplaint />} />
                </Route> */}
                  <Route path="complaints">
                    {/* layout으로 묶기 */}
                    {/* <Route element={<RequestLayout />}> */}
                    <Route index element={<ComplaintListPage />} />
                    {/* departmentId Param으로 변경 */}
                    <Route path="todo/:department_id" element={<ComplaintTodoListPage />} />
                    {/* </Route> */}
                    <Route path="create" element={<ComplaintCreatePage />} />
                    <Route path=":complaintId" element={<ComplaintDetailPage />} />
                    <Route path=":complaintId/edit" element={<ComplaintEditPage />} />
                  </Route>
                </Route>
              </Route>
            </Routes>
          </ErrorBoundary>
        </OverlayProvider>
      </GlobalContext.Provider>
    </div>
  );
}

export default App;
