import Axios, { InternalAxiosRequestConfig } from "axios";
import { auth } from "./firebase";

export let webViewToken: string | null = null;

export const setWebViewToken = (token: string | null) => {
  webViewToken = token;
};

const interceptors = async (config: InternalAxiosRequestConfig<unknown>) => {
  if (webViewToken) {
    config.headers.token = webViewToken;
  } else {
    const token = await auth.currentUser?.getIdToken();
    if (!token) throw new Error();
    config.headers.token = token;
  }
  return config;
};

export const axiosInstance = Axios.create();
axiosInstance.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
axiosInstance.defaults.withCredentials = false;
axiosInstance.interceptors.request.use(interceptors);

export const http = {
  get: function get<Response = unknown, Params = unknown>(url: string, params?: Params) {
    return axiosInstance.get<Response>(url, { params }).then((res) => res.data);
  },
  post: function post<Request = unknown, Response = unknown>(url: string, data?: Request) {
    return axiosInstance.post<Response>(url, data).then((res) => res.data);
  },
  del: function del<Response = unknown>(url: string) {
    return axiosInstance.delete<Response>(url).then((res) => res.data);
  },
  put: function put<Request = unknown, Response = unknown>(url: string, data?: Request) {
    return axiosInstance.put<Response>(url, data).then((res) => res.data);
  },
  download: function download<Response = Blob>(url: string) {
    return axiosInstance.get<Response>(url, { responseType: "blob" }).then((res) => res.data);
  },
};
