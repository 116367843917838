import moment from "moment";
import { format_day } from "../../lib/utils/dataFormat";
import { useContext } from "react";
import { GlobalContext } from "../../App";
import { createSearchParams, useNavigate } from "react-router-dom";
import { fetchClient } from "../../lib/api/axios";
import { BaseResponse } from "../../lib/api/queries/commons/types";
import { IDashboardGroup } from "../../lib/types/Imodels";
import { dashboard_group } from "../../lib/api/queries/urls";
import { useQuery } from "@tanstack/react-query";
import { dashboard_group_key } from "../../lib/api/queries/keys";
import DashboardCard from "./_view/DashboardCard";
import { useBuildingInfoQuery } from "../../requests/building";
import WascoRequestModal from "../../views/banner/modal/WascoRequestModal";
import { useOverlay } from "@toss/use-overlay";

export default function DashBoardPage() {
  const navigate = useNavigate();
  const { data: building } = useBuildingInfoQuery();
  const { buildInfo } = useContext(GlobalContext);

  const overlay = useOverlay();

  const { data } = useQuery(
    [dashboard_group_key],
    () => fetchClient.post<BaseResponse<IDashboardGroup>>(dashboard_group).then((res) => res.data.data),
    { enabled: !!buildInfo },
  );

  return (
    <div className="p-10">
      <h3>
        {moment.utc().format("YYYY년 MM월 DD일")} ({format_day(moment().day())})
      </h3>
      <div className="flex">
        {building?.data.services.MOVEIN_CARD && (
          <DashboardCard
            title={"입주 카드\n등록 대기"}
            number={data?.pending_resident_card}
            onClick={() => {
              navigate({
                pathname: "/companies/cards",
                search: `?${createSearchParams({ page: String(1), status: ["pending"] })}`,
              });
            }}
            disabled={!data?.pending_resident_card}
            hoverText={"입주 카드 확인 후 입주사 등록을 완료해주세요."}
          />
        )}

        {building?.data.services.REGULAR_PARKING && (
          <DashboardCard
            title={"정기 주차\n신규 신청"}
            number={data?.car_request}
            onClick={() => {
              navigate({
                pathname: "/parking",
                search: `?${createSearchParams({ progress: ["approval_requested"] })}`,
              });
            }}
            disabled={!data?.car_request}
            hoverText={"정기 주차 신청서를 확인해주세요"}
          />
        )}
      </div>
      <div className="flex">
        {building?.data.services.COMPLAINT && (
          <DashboardCard
            title={"처리 중인 민원"}
            number={data?.all_complaint_to_do}
            onClick={() => navigate("/complaints")}
            disabled={!data?.all_complaint_to_do}
            hoverText={"관리자의 민원 처리를 기다리고 있어요."}
          />
        )}
        {building?.data.services.TEMPERATURE_FEEDBACK && (
          <DashboardCard
            title={"온도 조절 요청"}
            number={data?.new_temperature}
            onClick={() => navigate("/temperature")}
            disabled={!data?.new_temperature}
            hoverText={"실내 온도에 대한 피드백을 확인해주세요."}
          />
        )}
      </div>
      {building?.data.services.MEETING_ROOM && (
        <div className="flex">
          <DashboardCard
            title={"회의실\n예약 신청"}
            number={data?.room_public_reservation?.filter((item) => item.room_public_type === "회의실").length}
            onClick={() =>
              navigate({
                pathname: "/meeting-rooms",
                search: `?${createSearchParams({
                  page: String(1),
                  status: "pending",
                  start_date: moment().format("YYYY-MM-DD"),
                  end_date: moment().add(1, "month").format("YYYY-MM-DD"),
                })}`,
              })
            }
            disabled={!data?.room_public_reservation?.filter((item) => item.room_public_type === "회의실").length}
            hoverText={"관리자의 승인을 기다리고 있어요."}
          />
        </div>
      )}
      <div className="fixed bottom-12 right-12 h-[280px] w-[400px] rounded-[20px] shadow-lg shadow-gray-300">
        <img src="/images/wasco-banner.png" alt="wasco_banner" className="absolute z-10 object-contain" />
        <button
          className="absolute bottom-[33px] left-7 z-20 flex h-[46px] w-[155px] items-center justify-center rounded-xl bg-white"
          onClick={() => {
            overlay.open(({ isOpen, close }) => (
              <WascoRequestModal
                isOpen={isOpen}
                onClose={close}
                name={building?.data.name || ""}
                userName={buildInfo?.building_master_id?.name || ""}
                email={buildInfo?.building_master_id?.email || ""}
              />
            ));
          }}
        >
          <span className="text-xl font-bold leading-6 text-blue-500">무료 컨설팅 받기</span>
        </button>
      </div>
    </div>
  );
}
