import { useNavigate, useParams } from "react-router-dom";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { useContext, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { delete_rental, detail_rental } from "../../../lib/api/queries/urls";
import { detail_rental_key } from "../../../lib/api/queries/keys";
import { fetchClient } from "../../../lib/api/axios";
import { IRentalList } from "../../../lib/types/Imodels";
import useUserAuth from "../../../components/hooks/useUserAuth";
import FullPageSpinner from "../../../components/Spinner";
import NotFound from "../../not-found";
import GetAuthorized from "../../../components/Authrized";
import { GlobalContext } from "../../../App";
import { useDisclosure } from "@chakra-ui/react";

// BillLetterView 컴포넌트
import { Button, Select, Text } from "@chakra-ui/react";
import ContentInputs from "../../../components/Input/middleContents";
import * as C from "../../../components/styled/index";
import * as R from "../../maintenance-fee/commons/styled";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import CancelModal from "../../../components/modal/CancelModal";
import RentalDetailItem from "../../maintenance-fee/rent/detail/rentDetailItem";
import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { twMerge } from "tailwind-merge";

export default function RentFeeDetailPage() {
  const navigate = useNavigate();
  const { rentalId } = useParams();
  const auth = useUserAuth("rent_fee");
  const { buildInfo } = useContext(GlobalContext);
  const hasFunc = buildInfo?.services.RENT_FEE?.enabled;
  const [dong, setDong] = useState("");
  const [status, setStatus] = useState("");
  const [inputs, setInputs] = useState({ id: "search_ho", value: "" });

  const { data, refetch } = useQuery(
    [detail_rental_key],
    () =>
      fetchClient
        .post<BaseResponse<IRentalList>>(detail_rental, {
          rent_fee_id: rentalId,
          payment_status: status,
          dong,
          [inputs.id]: inputs.value,
        })
        .then((res) => res.data.data),
    { enabled: hasFunc },
  );

  const onRefetch = () => {
    refetch();
  };

  const mutation = useMutation(() =>
    fetchClient
      .post<BaseResponse<string>>(delete_rental, { rent_fee_id: rentalId })
      .then(() => {
        alert("임대료 납부 고지서를 삭제했습니다. 확인을 누르면 임대료 현황 페이지로 이동합니다.");
        navigate("/rent-fee", { replace: true });
      })
      .catch(() => alert("일시적인 오류로 납부 고지서 삭제에 실패했습니다. 잠시 후 다시 시도해주세요.")),
  );

  const onDeleteBill = () => {
    if (mutation.isLoading) return;
    mutation.mutate();
  };

  // BillLetterView 컴포넌트

  const { isOpen, onOpen, onClose } = useDisclosure(); //납부 고지서 삭제

  if (hasFunc === undefined || !auth) return <FullPageSpinner />;
  if (!hasFunc) return <NotFound />;
  if (auth === "no_auth") return <GetAuthorized />;

  return (
    <>
      <div className="flex min-h-full w-full flex-col px-6 py-10">
        <p className="flex border-b-2 border-gray-500 pb-6 text-2xl">
          <FiChevronLeft onClick={() => navigate("/rent-fee")} className="cursor-pointer text-[32px]" />
          임대료 납부고지서
        </p>
        <div className="flex w-full items-end border-b border-gray-300 px-4 pb-2 pt-5 font-medium">
          <div className="flex flex-col">
            <p className="pt-1 text-2xl leading-9">{moment(data?.date).format("YY년 MM월")} 납부고지서</p>
          </div>
        </div>
        <div className="flex gap-4 px-4 py-5 text-gray-400">
          <p>
            최종 편집자
            <span className="text-gray-900"> {data?.user_name}</span>
          </p>
          <p>
            고지상태
            <span className="text-gray-900">{data?.is_open ? " 고지완료" : " 업로드완료"}</span>
          </p>
          <p>
            최근 업데이트
            <span className="ml-4 text-gray-500">
              {data?.updated_date ? moment.utc(data.updated_date).format(" YYYY-MM-DD HH:mm") : ""}
            </span>
          </p>
        </div>
        <div className="flex flex-col items-start py-4 font-medium text-gray-400">
          <div className="flex items-end gap-2">
            <div className="flex w-[180px] flex-col gap-1">
              <p>납부상태</p>
              <Select value={status} variant="default" onChange={(e) => setStatus(e.target.value)}>
                <option value="">전체</option>
                <option value="checking">확인 중</option>
                <option value="completed">납부 완료</option>
              </Select>
            </div>
            <div className="flex w-[180px] flex-col gap-1">
              <p>동</p>
              <Select value={dong} variant="default" onChange={(e) => setDong(e.target.value)}>
                <option value="">전체</option>
                {buildInfo?.dong_floor.map((el) => (
                  <option key={uuidv4()} value={el.dong}>
                    {el.dong}동
                  </option>
                ))}
              </Select>
            </div>
            <div className="flex w-[180px] flex-col gap-1">
              <p>검색</p>
              <Select
                variant="default"
                value={inputs?.id || ""}
                onChange={(e) =>
                  setInputs &&
                  setInputs({
                    ...inputs,
                    id: e.target.value,
                    value: "",
                  })
                }
              >
                {buildInfo?.management_fee_upload_type === "direct" && <option value="search_tenant">입주사명</option>}
                <option value="search_ho">호실번호</option>
              </Select>
            </div>
            <div className="flex w-fit flex-wrap gap-2">
              <input
                onChange={(e) =>
                  setInputs &&
                  setInputs({
                    ...inputs,
                    id: inputs?.id || "search_tenant",
                    value: e.target.value,
                  })
                }
                value={inputs?.value || ""}
                placeholder={`${
                  inputs?.id === "search_tenant"
                    ? "검색하실 입주사명을 입력해주세요"
                    : "검색하실 호실번호를 입력해주세요"
                }`}
                onKeyDown={(e) => e.key === "Enter" && onRefetch()}
                className="w-80 max-w-[496px] border border-gray-400 bg-white px-3 py-2 text-gray-900 placeholder:text-gray-300"
              />
              <button onClick={onRefetch} className="border border-gray-400 px-3 py-2">
                검색
              </button>
            </div>
          </div>
        </div>
        <div className="flex w-full items-center pb-2">
          <p>
            총 <span className="font-medium text-blue-600">{data?.rent_fee_details?.length || 0}</span>건
          </p>
        </div>
        <div style={{ display: "contents" }}>
          <div className="table w-full border-b border-t border-gray-500 bg-gray-100 text-center">
            <div
              className={twMerge(
                "table-cell w-[4%] border-r border-gray-500 align-bottom",
                (data?.rent_fee_details?.length || 0) > 999 && "w-[5%]",
              )}
            >
              <div className="flex items-end justify-end p-1">
                <div className="borber border-b-[24px] border-l-[24px] border-b-gray-300 border-l-transparent" />
              </div>
            </div>
            <div className="table-cell w-[17%] border-r border-gray-500 bg-gray-100 p-3">입주사명</div>
            <div className="table-cell w-1/4 border-r border-gray-500 bg-gray-100 p-3">호실</div>
            <div className="table-cell w-1/5 border-r border-gray-500 bg-[#eaf3ff] p-3">임대료</div>
            <div className="table-cell w-[15%] border-r border-gray-500 bg-[#eaf3ff] p-3">납부상태</div>
            <div className="table-cell w-1/5 bg-white" />
          </div>
          {data?.rent_fee_details?.map((el, idx) => (
            <RentalDetailItem key={uuidv4()} el={el} idx={idx} length={data?.rent_fee_details?.length || 0} />
          ))}
        </div>
      </div>
      <CancelModal
        isOpen={isOpen}
        onClose={onClose}
        onClickCancel={onClose}
        onClickSave={() => {
          onDeleteBill();
          onClose();
        }}
      >
        <p>납부고지서를 삭제할까요?</p>
        <p>삭제된 데이터는 복구할 수 없습니다.</p>
      </CancelModal>
      {auth === "edit" && (
        <div className="mt-2 flex w-full justify-between bg-white p-6">
          <button onClick={onOpen} className="border border-[#FB4667] px-3 py-2 text-[#FB4667]">
            삭제
          </button>
          <button
            onClick={() => navigate(`/rent-fee/${rentalId}/edit`)}
            className="mx-3 border border-blue-600 px-3 py-2 text-blue-600"
          >
            수정
          </button>
        </div>
      )}
    </>
  );
}
